.booth-page {
    .booth__ {
        &wrapper {
            .heading {
                margin-top: 50px;
            }



            .chat-container {
                .logo {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        box-shadow: 2px 6px 12px rgba(0, 0, 0, 0.16);
                        border-radius: 6px;
                        border: 2px solid $grey;
                    }

                    .company-link {
                        text-transform: uppercase;
                        text-decoration: underline;
                        color: $grey;

                        cursor: pointer;
                    }
                }
            }

            .video-call {
                display: flex;
                flex-direction: column;

                .video {
                    max-height: unset;

                    .heading {
                        width: 100%;

                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .back-btn {
                            box-shadow: none;
                            --box-shadow: none;

                            margin: 0;

                            color: $grey !important;
                            --color: $grey !important;
                        }

                        .ui__h3 {
                            display: flex;
                            align-self: center;
                            justify-self: center;
                            margin: auto;
                            padding-right: 100px;
                        }
                    }

                    .status {
                        .icon {
                            ion-icon {
                                color: $grey;
                                width: 75.7px;
                                height: 50.5px;
                            }
                        }

                        .message {
                            display: flex;
                            align-self: center;
                            justify-self: center;
                            margin-top: 25px;
                            font-size: 25px;
                            font-weight: bold;
                            color: $grey;
                        }

                        .btn-join-call {
                            margin: auto;
                            margin-top: 12px;
                            width: 120px;
                        }
                    }
                }

                @media (min-width: 1600px) {
                    width: 100%;
                    max-width: 1700px;
                    height: 100%;
                    margin: auto;

                    .video {
                        width: 100%;
                    }
                }
            }
        }
    }

    .appointment__stream {
        min-height: 246px;
        text-align: center;
    }

    .appointment__room {
        position: relative;
        width: 100%;

        .appointment__local {
            position: absolute;
            right: 1rem;
            top: 0.25rem;

            video {
                border-radius: 8px;
            }
        }

        .appointment_buttons {
            position: absolute;
            bottom: 15px;
            left: 50%;
            transform: translate(-50%, 0);

            display: flex;
            flex-direction: row;

            .appointment__onOffBtn {
                background-color: white;
                opacity: 0.75;
            }

            .appointment__logoutBtn {
                margin-left: 1rem;
                margin-right: 1rem;
                background-color: white;
                color: red;
                opacity: 0.75;
            }
        }
    }

    .appointment__ {
        &room {
            position: relative;

            padding: 10px 0;
            height: auto;
            min-height: 720px;
            padding: 20px;

            background: rgba(0, 0, 0, 0.1);

            transition: 0.3s;

            .status {
                display: flex;
                flex-direction: column;
                align-self: center;
                justify-self: center;

                font-size: 25px;
                font-weight: bold;
                color: $grey;
            }

            * {
                margin-top: 0;
            }
        }

        &lobby {
            height: auto;
            min-height: 720px;
            padding: 20px;

            background: rgba(0, 0, 0, 0.1);

            transition: 0.3s;

            display: flex;
            align-items: center;
            justify-content: center;

            .status {
                font-size: 25px;
                font-weight: bold;
                color: $grey;
            }
        }

        &remote,
        &remote>&participant,
        &remote>&participant video {
            position: relative;
            max-width: 100vw;
            min-height: 200px;

            video {
                height: 720px;

                @media (max-width: 1280px) {
                    height: 420px;
                }

                @media (max-width: 768px) {
                    height: 300px;
                }
            }
        }

        &remote {
            min-height: 680px;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        &local,
        &local>&participant,
        &local>&participant video {
            border: 0;
            max-width: 200px;
            height: auto;
            max-height: 120px;

            .appointment__participant,
            video {
                max-height: 120px;
            }

            @media (max-width: 768px) {
                width: 100px;
            }
        }

        @media (max-width: 768px) {
            &local {
                right: -1rem;
            }
        }
    }
}