.fc__ {
    &maintenance {
        position: fixed;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        background: #232323;
        // &:before {
        //     content: '';
        //     position: fixed;
        //     width: 100%;
        //     height: 100%;

        //     background: url(../../assets/bg-maintenance.svg);
        //     background-size: contain;
        //     background-position: center center;
        //     opacity: 0.3;
        // }

        .illustration {
            ion-icon {
                width: 673px;
                height: 427px;
                margin-top: -148px;
            }
        }

        .content {
            ion-icon {
                width: 592px;
                height: 156px;
                margin-left: -36px;
                margin-bottom: -24px;
            }

            .text {
                font-size: 32px;
                // color: $satan;
                color: #f0f0f0;
            }
        }
    }

    &event-row {
        width: 100%;
        height: 72px;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        text-align: center;

        .bg {
            position: absolute;
            height: 72px;
            left: 0;
            right: 0;
        }

        .logo {
            width: 100%;
            margin: auto;

            img {
                position: relative;
                z-index: 2;

                width: auto;
                max-height: 30px;
                margin: auto;

                cursor: pointer;
            }
        }
    }

    &event-banner {
        width: 100%;
        max-width: 930px;
        height: 100px;
        max-height: 100px;
        z-index: 99;

        left: 50%;
        transform: translateX(-50%);

        transition: 0.5s;

        img {
            width: 100%;
            max-width: 930px;
            height: 100px;
            max-height: 100px;

            object-fit: cover;

            margin: auto;

            border-radius: 0 0 16px 16px;
            box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.16);

            display: flex;
            transition: 0.5s;
        }

        @media (min-width: 1024px) and (max-width: 1280px) {
            height: 80px;
            max-height: 80px;

            img {
                height: 80px;
                max-height: 80px;
                max-width: 720px;
            }
        }

        @media (min-width: 768px) and (max-width: 1024px) {
            height: 50px;
            max-height: 50px;

            img {
                height: 50px;
                max-height: 50px;
                max-width: 474px;
            }
        }

        @media (max-width: 768px) {
            img {
                max-width: 50%;
            }
        }

        &.shrunk {
            max-width: 560px;
            max-height: 64px;

            img {
                max-width: 560px;
                max-height: 64px;
            }
        }
    }

    &tabs {
        .tab-list {
            border-bottom: 1px solid #D9D9DA;

            display: flex;
            flex-direction: row;

            .tab {
                margin-bottom: -1px;
                padding: 10px 10px 5px 10px;

                border-radius: 4px 4px 0 0;
                border: 1px solid transparent;

                transition: 0.3s;
                cursor: pointer;

                &+.tab {
                    margin-left: 12px;
                }

                &.active {
                    border: 1px solid #D9D9DA;
                    border-bottom-color: #fff;
                }

                &:hover {
                    background: rgba(0, 0, 0, 0.04);
                }
            }
        }
    }

    &color-picker {
        display: flex;
        flex-direction: row;

        .ui__label {
            margin-bottom: 8px;
        }

        .input {
            margin-left: 8px;
        }
    }

    &accordion {
        width: 100%;
        margin-bottom: 8px;
        overflow: hidden;

        .meta {
            padding: 8px;
            border: 1px solid #CECECE;
            border-left: none;
            border-right: none;

            transition: 0.3s;
            cursor: pointer;

            &:hover {
                background: rgba(0,0,0,0.07);
            }

            ion-icon {
                width: 24px;
                height: 24px;
            }

            .name {
                margin-left: 10px;
            }

            .actions {
                display: none;
                margin-left: auto;
                margin-right: 64px;

                .item {
                    font-size: 16px;
                }

                .item+.item {
                    margin-left: 16px;
                }
            }
        }

        .content {
            transition: 0.3s;
            opacity: 0;
            visibility: hidden;
        }

        &.open {
            max-height: unset;

            .actions {
                display: unset;
            }

            .content {
                padding: 20px 12px;
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &dropdown {
        position: relative;
        
        .name {
            margin-right: 2px;

            ion-icon {
                width: 24px;
                height: 24px;
            }
        }

        .items {
            display: none;
            min-width: 100%;
            padding: 8px 0;

            position: absolute;
            top: 100%;

            background: #FFFFFF;
            border-radius: 4px;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);

            .fc__dropdown-option {
                padding: 6px 16px;
            }

            &.open {
                display: unset;
            }
        }
    }

    &dropdown-option {
        min-width: 100%;
        width: max-content;
        max-width: 240px;

        &:hover {
            background: rgba(0,0,0,0.07);
        }
    }

    &card {
        padding: 4px;
        margin: 4px;

        background: #F4F0EF;
        border-radius: 4px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .name {
            width: 100%;
            padding: 8px;
            text-align: center;
        }

        .content {
            width: 100%;
            background: #fff;
            border-radius: 4px;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
        }
    }

    &file-upload-area {
        .dropzone {
            background: rgba(0, 0, 0, 0.06);

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            border: 1px dashed $grey;
            border-radius: 6px;
        }
    }

    &file-picker {
        img {
            width: 100%;
            max-width: 400px;

            border: 1px solid $grey;
            padding: 4px;
        }

        .actions {
            display: flex;
            flex-direction: row;
            align-items: center;

            .ui__button {
                width: 100%;
                max-width: 200px;

                display: flex;
                align-self: center;
            }

            .delete {
                width: auto;
                height: 32px;

                padding: 0 8px;
                margin-left: 12px;

                color: #fff;
                font-weight: bold;
                border-radius: 100px;

                background: $danger;

                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    &powered-by-mixabl {
        margin: 0;

        opacity: 0.7;

        display: none;

        ion-icon.logo {
            width: 186px;
            height: 200px;
        }

        @media (min-width: 480px) {
            display: block;
        }
    }

    &sponsor-banner {
        padding: 8px 0;
        background: #fff;

        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        img+img {
            margin-left: 16px;
        }
    }

    &tooltip {
        width: max-content;
        position: relative;

        .tooltip {
            z-index: 999;
            width: max-content;
            position: absolute;
            bottom: 24px;

            padding: 4px;

            background: #52AFCB;
            color: #fff;
        }

        .children {
            width: max-content;
            cursor: pointer;
        }
    }

    &player-countdown,
    &stream-has-ended {
        position: relative;
        width: 100%;
        height: 680px;

        background: #F5F5F5;

        border-radius: 160px;

        .wrapper {
            z-index: 1;
            position: relative;

            width: 100%;
        }
    }

    &player-countdown {
        border-top-right-radius: 0;

        .wrapper {
            height: 100%;
        }

        .info-wrapper {
            position: absolute;
            bottom: 37%;
            right: 4%;
            transform: translate(-4%, -37%) rotate(-45deg);

            .info {
                display: flex;
                flex-direction: row;
                align-items: center;

                .col {
                    &.icon {
                        ion-icon {
                            width: 64px;
                            height: 64px;

                            margin-top: 20px;
                            margin-right: 16px;
                        }
                    }

                    &.remaining-time {
                        .text {
                            width: 500px;
                            font-size: 32px;
                            margin-bottom: -24px;
                        }

                        .time {
                            font-size: 81px;
                            font-weight: bold;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }

        .bg {
            position: absolute;
            z-index: 0;
            height: calc(100% - 40px);
            top: 20px;
            right: 20px;
            bottom: 20px;
            left: 20px;

            border-radius: 160px;

            background-image: url('../../assets/icons/ic-player-countdown-bg.svg');
            background-position: bottom left;
            background-repeat: no-repeat;
        }
    }

    &stream-has-ended {
        border-top-left-radius: 0;

        .wrapper {
            margin-left: 80px;
            margin-top: 240px;

            .info {
                font-size: 32px;

                .text {
                    font-weight: bold;
                }
            }
        }

        .bg {
            position: absolute;
            z-index: 0;
            height: calc(100% - 40px);
            top: 20px;
            right: 20px;
            bottom: 20px;
            left: 20px;

            transform: rotate(180deg);

            border-radius: 160px;

            background-image: url('../../assets/icons/ic-player-countdown-bg.svg');
            background-position: bottom left;
            // transform: rotate(180deg);
            background-repeat: no-repeat;
        }
    }

    &upcoming-presentations {
        margin-top: 32px;

        .cards {
            margin-top: 16px;
            padding-bottom: 16px;

            overflow-x: auto;
            overflow-y: hidden;


            display: flex;
            flex-direction: row;

            .card {
                position: relative;

                min-width: 200px;
                width: 100%;
                max-width: 270px;
                height: 100px;

                padding: 8px 12px;

                color: $satan;
                border: 1px solid #CCCBCB;
                border-radius: 6px;

                cursor: pointer;
                transition: 0.3s;

                &:hover {
                    background: rgba(0, 0, 0, 0.03);
                }

                .startsAt {
                    position: absolute;
                    top: 0;
                    right: 0;

                    width: 58px;
                    height: 30px;

                    background: $grey;
                    border-radius: 0 5px 0 6px;

                    font-size: 10px;
                    color: #fff;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .name {
                    margin-right: 54px;

                    font-size: 12.5px;
                    font-weight: bold;
                }

                .presenters {
                    font-size: 10px;
                }

                &+.card {
                    margin-left: 5px;
                }
            }
        }
    }

    &schedule-list {
        max-height: 80vh;

        @media (min-width: 1680px) {
            margin-right: 32px;
        }

        overflow-x: hidden;
        overflow-y: auto;

        scroll-behavior: smooth;

        @supports (-webkit-appearance:none) and (not (overflow:-webkit-marquee)) and (not (-ms-ime-align:auto)) and (not (-moz-appearance:none)) {
            @media(min-width: 1280px) {

                &::-webkit-scrollbar {
                    transition: opacity 0.25s linear;
                    width: 8px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px $scrollbar-bg;
                    border-radius: 10px;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: $scrollbar-thumb;
                    border: 1px solid #FFFFFF;
                    border-radius: 10px;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #ccc;
                }
            }
        }

        .header {
            border-bottom: 1px solid $secondary;
            padding-bottom: 7px;
            margin-bottom: 10px;

            display: flex;
            flex-direction: row;

            .action {
                color: $grey;
                text-decoration: underline;
                cursor: pointer;
            }

            .action+.action {
                margin-left: 40px;
            }
        }

        .content {
            width: 100%;

            display: flex;
            flex-direction: row;

            .timestamps {
                position: sticky;
                top: 16px;
                padding-top: 8px;
                padding-left: 64px;

                width: 160px;
                min-width: 160px;
                height: 75vh;

                overflow-y: auto;

                // Chromium
                &::-webkit-scrollbar {
                    display: none;
                }

                .crawler {
                    position: absolute;
                    top: 0;
                    left: 0;

                    width: fit-content;
                    width: 42px;
                    height: 30px;

                    background: #54ACD4;
                    border: 1px solid #F5F5F5;
                    border-radius: 8px 0 0 8px;

                    color: #fff;
                    font-size: 12px;
                    text-align: center;


                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .time {
                        margin-left: 4px;
                        z-index: 5;
                    }

                    &:after {
                        content: '';

                        position: absolute;
                        right: -10px;

                        width: 20px;
                        height: 20px;

                        border: 1px solid #F5F5F5;
                        border-width: 1px 1px 0 0;
                        background: #54ACD4;

                        transform: rotate(45deg);
                    }
                }

                // IE and Edge
                -ms-overflow-style: none;
                // Firefox
                scrollbar-width: none;

                .timestamp {
                    font-size: 11px;
                    color: #B7B6B6;

                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .value {
                        width: 32px;
                    }

                    .events {
                        margin-left: 6px;
                        display: flex;
                        flex-direction: row;

                        .vod {
                            background: #52AFCB;

                            &:after {
                                border: 1px solid #52AFCB;
                            }

                            .tip {
                                color: #52AFCB;
                                border: 1px solid #52AFCB;

                                &:before {
                                    background: #52AFCB;
                                }
                            }
                        }

                        .live {
                            background: #D84A37;

                            &:after {
                                border: 1px solid #D84A37;
                            }

                            .tip {
                                color: #D84A37;
                                border: 1px solid #D84A37;

                                &:before {
                                    background: #D84A37;
                                }
                            }
                        }

                        .scheduled {
                            background: #A3A3A3;

                            &:after {
                                border: 1px solid #A3A3A3;
                            }

                            .tip {
                                color: #A3A3A3;
                                border: 1px solid #A3A3A3;

                                &:before {
                                    background: #A3A3A3;
                                }
                            }
                        }

                        .vod,
                        .live,
                        .scheduled {
                            position: relative;

                            width: 12px;
                            height: 12px;
                            border-radius: 100px;

                            cursor: pointer;

                            &+* {
                                margin-left: 4px;
                            }

                            .tip {
                                &:before {
                                    content: '';

                                    position: absolute;
                                    bottom: 100%;
                                    left: 50%;

                                    width: 1px;
                                    height: 11px;
                                }

                                display: none;
                                position: absolute;
                                top: 24px;
                                left: 50%;
                                transform: translateX(-50%);

                                width: 64px;
                                text-align: center;

                                border-radius: 3px;

                                font-size: 10px;
                            }

                            &:after {
                                display: none;

                                content: '';
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);

                                width: 16px;
                                height: 16px;
                                border-radius: 100px;
                            }

                            &:hover {
                                &:after {
                                    display: unset;
                                }

                                .tip {
                                    display: unset;
                                }
                            }
                        }
                    }

                    &+.timestamp {
                        margin-top: 35px;
                    }
                }
            }

            .streams {
                margin-left: 32px;
                width: 100%;

                .stream-card {
                    position: relative;
                    min-height: 96px;
                    height: 100%;
                    max-height: 96px;

                    background: #F5F5F5;
                    border: 1px solid #CCCBCB;
                    border-radius: 6px;
                    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16);

                    display: flex;
                    flex-direction: row;

                    cursor: pointer;
                    transition: 0.3s;

                    &+.stream-card {
                        margin-top: 6px;
                    }

                    .wrapper {
                        width: 100%;

                        background: #fff;
                        border-radius: 6px;

                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        transition: 0.3s;

                        &::after {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16);
                            border-radius: 6px;

                            opacity: 0;
                        }

                        .meta {
                            padding: 15px 20px;

                            .name {
                                font-size: 16px;
                                font-weight: bold;
                                color: #258FAF;
                                // darker
                            }

                            .presentors {
                                font-size: 12px;
                                color: #57B5DE;
                                max-width: 95%;
                                padding-right: 10px;
                                // lighter
                            }

                            .description {
                                display: none;
                            }

                            .mote {
                                text-transform: uppercase;
                            }
                        }

                        .thumbnail {
                            width: 152px;
                            height: 100%;
                            min-width: 152px;
                            overflow: hidden;
                            margin-left: auto;

                            transition: 0.3s;

                            img {
                                object-fit: cover;
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .add-to-calendar {
                            margin: 0 16px;

                            .chq-atc {
                                display: flex;
                                position: relative;
                                z-index: 1;

                                button svg {
                                    position: relative;
                                    display: block;
                                    z-index: auto;
                                }

                                &--dropdown {
                                    display: none;
                                    z-index: 99999;

                                    min-width: 200px;
                                    transform: translate(-75%, 15%);
                                }
                            }
                        }

                        .status {
                            position: absolute;
                            top: 0;
                            right: 0;

                            width: 58px;
                            height: 30px;
                            border-radius: 0 5px 0 6px;
                            box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);

                            color: #fff;
                            background: #A8D7E5;

                            font-size: 10px;

                            display: flex;
                            align-items: center;
                            justify-content: center;

                            transition: 0.3s;

                            &.scheduled {
                                background: #A3A3A3;
                            }

                            &.live {
                                background: #D84A37;
                                font-weight: bold;
                            }
                        }
                    }

                    .location {
                        width: 100%;
                        max-width: 80px;
                        margin-left: -80px;

                        position: absolute;
                        left: 60px;
                        transform: rotate(270deg);

                        color: #B7B6B6;
                        text-align: center;

                        visibility: hidden;
                        opacity: 0;

                        display: flex;
                        align-self: center;
                        justify-content: center;

                        transition: 0.3s;
                    }

                    &:hover,
                    &.highlighted {
                        z-index: 99;
                        min-height: 140px;
                        max-height: 140px;
                        margin-left: -42px;

                        transition: all 0.2s ease margin-left 0.1s linear;

                        transform: scale(1.00);

                        .wrapper {
                            position: relative;
                            margin: 4px 4px 4px 42px;

                            &::after {
                                opacity: 1;
                            }
                        }

                        .location {
                            visibility: visible;
                            opacity: 1;
                        }

                        .thumbnail {
                            width: 205px;
                        }
                    }

                    &:hover {
                        .chq-atc {
                            &--dropdown {
                                display: unset !important;
                            }
                        }
                    }
                }
            }
        }
    }

    &search-bar {
        width: 100%;

        display: flex;
        flex-direction: row;
        align-items: center;

        .ui__input {
            margin-top: 0;
            height: 100%;
        }

        .ui__button {
            min-width: unset;
            max-width: unset;
            width: 44px;
            height: 100%;

            margin: 0 0 0 9px;
        }
    }

    &exhibitors-list {
        width: 100%;

        margin-top: 0;

        display: flex;
        flex-direction: column;
        align-self: center;

        &.narrow {
            overflow-x: hidden;
            max-height: 80vh;
            scroll-behavior: smooth;
            padding: 4px;

            &::-webkit-scrollbar {
                transition: opacity 0.25s linear;
                width: 8px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px $scrollbar-bg;
                border-radius: 10px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: $scrollbar-thumb;
                border: 1px solid #FFFFFF;
                border-radius: 10px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #ccc;
            }

            .gold,
            .silver,
            .bronze,
            .partners {
                @media (max-width: 1280px) {

                    .cta,
                    .description {
                        display: none;
                    }
                }
            }

            .silver,
            .bronze,
            .partners {
                &>*+* {
                    margin-top: unset;
                }
            }

            .scrollable {
                &>*+* {
                    margin-top: 16px;
                }

                .gold,
                .silver,
                .bronze,
                .partners {
                    &>*+* {
                        margin-top: 16px;
                    }
                }
            }
        }

        .silver,
        .bronze,
        .partners {
            justify-content: center;

            &>*+* {
                margin-top: 0;
            }
        }

        .other {
            justify-content: center;
        }

        .partners {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .exhibitor {
            position: relative;
            height: 120px;
            padding: 18px 25px;
            margin: 9px 0;

            color: $dark;
            text-decoration: none;

            border-radius: 6px;
            border: 2px solid $grey;

            background: #fff !important;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            cursor: pointer;

            transition: 0.25s;

            * {
                margin-top: 0;
            }

            &:hover {
                transform: scale(1.01);
            }

            &+.exhibitor {
                margin-top: 0px;
            }

            .logo {
                max-width: 240px;
                border: 0;
                object-fit: cover;

                display: flex;
                align-items: center;
                justify-content: center;

                ion-img {
                    border: 0;

                    height: 60px;
                    max-height: 60px;
                }
            }

            .logo+.meta {
                margin-left: 20px;
            }

            .meta {
                margin-top: 0;
                margin-right: 32px;

                display: flex;
                flex-direction: column;
                align-self: center;

                .description {
                    font-size: 15px;
                }

                .cta {
                    margin-top: 9px;
                    padding-top: 7px;
                    border-top: 1px solid $grey;

                    font-size: 17px;
                    font-weight: bold;
                }
            }


            @media (max-width: 768px) {
                width: 100%;
                height: auto;

                padding: 20px 20px 20px 0;

                .logo {
                    width: 40%;
                    max-width: unset;
                    margin-right: 0;
                }

                .meta {
                    width: 60%;
                    margin-right: 0;

                    .description {
                        font-size: 12px;
                    }

                    .cta {
                        font-size: 15px;
                    }
                }
            }
        }
    }

    &exhibitors-grid {

        .silver,
        .bronze,
        .partners {
            justify-content: center;

            &>*+* {
                margin-top: 0;
            }
        }

        .other,
        .partners {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    &header {
        background: #F5F5F5;

        .ui__container {
            height: 75px;
            margin: auto;
            padding: 0 24px;

            align-items: center;
            justify-content: center;

            max-width: 2560px;

            color: $grey;

            .logo-col {
                cursor: pointer;

                .logo {
                    width: 120px;
                    height: 32px;
                }
            }

            .navigation {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;

                .menu {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .item {
                        text-transform: uppercase;
                        font-weight: bold;

                        cursor: pointer;

                        transition: 0.3s;

                        &:hover {
                            color: #232323;
                        }
                    }

                    .item+.item {
                        margin-left: 55px;
                    }

                    .profile {
                        position: relative;
                        padding: 10px;
                        margin-left: 30px;

                        &.clicked {
                            background: #fbfbfb;

                            ion-icon {
                                color: #000;
                            }
                        }

                        .btn {
                            width: 32px;
                            height: 24px;

                            cursor: pointer;

                            transition: 0.3s;

                            &:hover {
                                color: #232323;
                            }
                        }

                        .menu {
                            z-index: 999;
                            position: absolute;
                            top: 100%;
                            right: 0;

                            padding: 20px;

                            background: #fbfbfb;

                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            .user-info {
                                width: max-content;
                                text-align: center;

                                .name {
                                    font-size: 24px;
                                    font-weight: bold;
                                }
                            }

                            .log-out {
                                width: max-content;
                                margin: auto;

                                margin-top: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    &event-navigation {
        width: 160px;
        padding-right: 8px;

        display: flex;
        flex-direction: column;
        align-items: center;

        .nav-item {
            width: 100%;
            margin-top: 20px;
            cursor: pointer;
            padding: 4px 0;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            border-radius: 0 8px 8px 0;

            color: #CCCBCB;
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;

            transition: 0.3s;

            &:hover,
            &.selected {
                transform: scale(1.05);

                background: #f9f9f9;
            }

            ion-icon {
                width: 42px;
                height: 42px;
            }

            object {
                width: 42px;
                height: 42px;
            }
        }

        @media (max-width: 768px) {
            display: none;
        }
    }

    &footer {
        padding: 32px 24px 24px 24px;

        background: rgba(163, 163, 163, 0.14);

        .ui__container {
            max-width: 2560px;
            margin: auto;

            .info {
                width: 100%;
                color: $dark;

                display: flex;
                flex-direction: row;

                .brand {
                    .logo {
                        width: 124px;
                        height: 32px;
                        margin-bottom: 15px;
                    }

                    .appVersion {
                        color: $grey;
                        margin-top: -16px;
                        font-size: 12px;
                    }
                }

                .legal {
                    padding-left: 32px;

                    .ownership,
                    .links {
                        font-size: 12px;
                    }

                    .links {
                        margin-bottom: 4px;

                        a+a {
                            margin-left: 16px;
                        }

                        a {
                            color: $dark !important;
                            text-decoration: none;
                        }
                    }

                    .copyright {
                        font-size: 10px;
                    }
                }
            }

            .navigation {
                display: flex;
                align-items: flex-end;
                justify-content: center;

                .menu {
                    display: flex;
                    flex-direction: row;

                    .item {
                        color: $grey !important;
                        font-size: 15px;
                        font-weight: bold;
                        text-decoration: none;
                        text-transform: uppercase;

                        cursor: pointer;

                        transition: 0.3s;

                        &:hover {
                            color: #232323 !important;
                        }

                        ion-select {
                            padding: 0;
                            color: $dark;
                        }
                    }

                    .item+.item {
                        margin-left: 50px;
                    }
                }
            }

            @media (max-width: 768px) {
                flex-wrap: wrap-reverse;

                text-align: center;

                .info {
                    align-items: center;

                    .links {
                        justify-content: center;
                    }
                }

                .navigation {
                    align-items: center;
                    margin-bottom: 32px;

                    .menu {
                        flex-direction: column;

                        ion-select::part(icon) {
                            display: none;
                        }

                        .item+.item {
                            margin-left: 0;
                            margin-top: 8px;
                        }
                    }
                }
            }
        }
    }

    &appointment-form {
        &>*+* {
            margin-top: 16px;
        }

        .fw+.fw {
            margin-top: 16px;
        }
    }
}

// Exhibitor cards

.gold-exhibitor-card,
.silver-exhibitor-card,
.bronze-exhibitor-card,
.partner-card,
.exhibitor-card {
    &+* {
        margin-top: 8px;
    }

    .meta {
        padding: 12px;

        img {
            width: 100%;
            max-width: 80%;
        }
    }


    .label {
        width: 160px;
        height: 40px;
        margin-bottom: -12px;
        padding-top: 5px;

        font-style: italic;

        border-radius: 6px;

        color: #fff;

        display: flex;
        justify-content: center;
    }
}

.gold-exhibitor-card {
    display: flex;
    flex-direction: column;

    text-align: center;

    transition: 0.3s;

    cursor: pointer;

    &:hover {
        transform: scale(1.01);
    }

    .meta img {
        width: auto;
        height: auto;
        object-fit: contain;
        max-height: 120px;
    }

    .label {
        background: linear-gradient(#FEB23B, #D19238);
    }

    .content {
        width: 100%;
        margin-top: 0;

        background: #fff;
        border-radius: 6px;
        border: 2px solid #000;
        box-shadow: 2px 6px 12px rgba(0, 0, 0, 0.16);

        display: flex;
        flex-direction: row;
        justify-content: center;

        .meta {
            width: 100%;
            font-size: 15px;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            ion-img,
            img {
                width: 100%;
            }

            .description {
                color: $dark;
                margin-top: 0;
            }
        }

        .description {
            font-size: 17px;
            font-weight: bold;
        }

        .cta {
            max-width: 45%;
            padding: 20px 30px;
            margin-top: 0;

            background: #F5F5F5;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
                width: 132px;
                margin: 0 auto;
            }

            .text {
                padding: 0;

                font-size: 21px;
                font-weight: bold;

                line-height: 1.05;
            }
        }
    }
}

.silver-exhibitor-card,
.bronze-exhibitor-card,
.partner-card {
    padding: 9px;

    flex-grow: 1;

    text-align: center;

    transition: 0.3s;
    cursor: pointer;

    &:hover {
        transform: scale(1.01);
    }

    .content {
        height: 200px;

        margin-top: 0;
        padding: 17px 25px;

        background: #fff;
        border-radius: 6px;
        border: 2px solid #000;
        box-shadow: 2px 6px 12px rgba(0, 0, 0, 0.16);

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            max-width: 200px;
            height: 100%;
            max-height: 80px;
            object-fit: contain;
        }

        .description {
            margin-top: 4px;

            color: $dark;
            font-size: 15px;
        }

        .cta {
            margin-top: 8px;
            font-size: 17px;
            font-weight: bold;
        }
    }
}

.silver-exhibitor-card {
    max-width: 50%;

    .label {
        background: linear-gradient(#bdc3c7, #747f8a);
    }
}

.bronze-exhibitor-card,
.partner-card {
    max-width: 33%;
}

.bronze-exhibitor-card {
    .label {
        background: linear-gradient(#AA7C40, #F3C441);
    }
}

.partner-card {
    .label {
        background: linear-gradient(#222222, #8d8d8d);
    }
}

.exhibitor-card {
    min-width: 30%;
    width: 100%;
    max-width: 32%;
    height: 240px;

    margin: 4px;

    cursor: pointer;

    background: #fff;
    border-radius: 6px;
    border: 2px solid #A3A3A3;
    box-shadow: 2px 6px 12px rgba(0, 0, 0, 0.16);

    color: $dark;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    flex: 1;

    transition: 0.3s;

    @media (max-width: 480px) {
        .content {
            height: auto;
            flex-direction: column;
        }
    }

    &:hover {
        transform: scale(1.01);
    }

    img {
        width: 100%;
        max-width: 200px;
        height: 100%;
        max-height: 80px;
        object-fit: contain;
    }

    .description {
        max-width: 240px;
    }


    .cta {
        margin-top: 8px;
        padding: 12px;

        border-top: 1px solid #ccc;

        font-size: 17px;
        font-weight: bold;
    }
}

@media (max-width: 480px) {

    .gold-exhibitor-card,
    .silver-exhibitor-card,
    .exhibitor-card {
        max-width: 100%;
        width: 100%;

        .meta {
            display: flex;
            align-items: center;
        }

        .description {
            display: none;
        }
    }

    .gold-exhibitor-card {
        .content {
            height: auto;
            flex-direction: column;
        }
    }

    .silver-exhibitor-card {
        padding: 0;

        &+.silver-exhibitor-card {
            margin-top: 20px;
        }
    }

    .exhibitor-card {
        padding: 20px;

        img {
            width: 100%;
            max-width: 200px;
            height: 100%;
            max-height: 80px;
            object-fit: contain;
        }

        .cta {
            margin-top: 0;
        }

        display: flex;
        align-items: center;
        justify-content: center;
    }
}