.watch__ {
  &wrapper {
    .presenters {
      .presenter+.presenter {
        margin-top: 12px;
      }

      .presenter {
        .name {
          font-weight: bold;
        }
      }
    }

    iframe.video-player {
      min-height: 720px;
    }

    .download {
      margin-top: 40px;
    }
  }

  &actions {
    margin-top: 16px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .show-stream-info,
    .turn-to-vod {
      min-width: 300px;
      max-width: 300px;

      margin-top: -48px;
      margin-bottom: 24px;

      display: flex;
      align-self: flex-end;
      justify-self: start;
    }

    *+* {
      margin-left: 8px;
    }
  }
}