.schedule__ {
    &wrapper {
        @media (max-width: 1280px) {
            .fc__schedule-list {
                max-height: unset;
                padding: 20px 0;

                .timestamps {
                    display: none;
                }
            }

            .fc__exhibitors-list.narrow {
                padding: 0 24px;
                max-height: unset;
            }
        }
    }

    &container {
        width: auto;
        display: flex;
        flex-direction: column;
    }

    &grid {
        width: 100%;
        grid-template-columns: auto 370px !important;

        @media (max-width: 1680px) {
            grid-template-columns: auto 200px !important;
        }

        @media (max-width: 1280px) {
            grid-template-columns: auto 220px !important;

            .ui__container,
            aside {
                grid-column: auto !important;
            }
        }
    }
}