.mix-page-content {
    width: 100%;
    max-width: 2560px;
    min-height: 75vh;

    margin: auto;

    display: flex;
    align-self: center;
    flex-direction: row;
    position: relative;
}

.template__ {

    &2-col,
    &3-col {
        width: 100%;
        max-width: 1700px;
        height: auto;
        margin: 0 auto;
        padding: 80px 0;

        .page-meta {
            max-width: 100vw;

            .component {
                margin-top: 20px;
            }
        }

        main {
            position: relative;
            // margin-top: 10px;

            .back-btn {
                position: absolute;

                top: -64px;
            }
        }

        .page-actions {
            max-width: 100vw;
        }

        @media all and (max-width: 480px) {
            position: relative;

            main {
                position: unset;
                width: 100vw;

                .back-btn {
                    top: 24px;
                }
            }
        }

        @media all and (max-width: 768px) {
            .content {
                padding: 16px;
            }
        }

        // @media all and (max-width: 1280px) {
        //     main {
        //         margin-top: 64px;
        //     }
        // }
    }

    &2-col {
        display: grid;
        grid-template-columns: 325px minmax(325px, 100%);
        grid-gap: 1em;

        &.reversed {
            grid-template-columns: minmax(325px, 100%) 325px;

            main {
                order: 0;
            }

            aside {
                order: 1;
            }
        }

        @media all and (max-width: 1280px) {

            aside {
                grid-column: 1 / 3;
            }
        }
    }

    &3-col {
        display: grid;
        grid-template-columns: 325px minmax(650px, 100%) 325px;
        grid-gap: 1em;

        &.reversed {
            grid-template-columns: 325px minmax(650px, 100%) 325px;

            aside.page-meta {
                order: 0;
            }

            main {
                order: 1;
            }

            aside.page-actions {
                order: 2;
            }
        }

        @media all and (max-width: 1680px) {
            grid-template-columns: 325px auto;

            aside.page-actions {
                grid-column: 1 / 3;
            }
        }

        @media all and (max-width: 1280px) {

            aside {
                grid-column: 1 / 3;
            }
        }
    }
}