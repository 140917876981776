.alert-double-auth {
    .alert-wrapper {
        width: 100%;
        max-width: 600px;
    }
}

.cookie-toast {
    --background: #4fb1dd;
    --button-color: #fff;

    @supports (-webkit-touch-callout: none) {
        --button-color: #4fb1dd !important;
    }

    @media (max-width:480px) {
        font-size: 12px;

        &::part(header) {
            font-size: 12px;
        }

        &::part(button) {
            font-size: 10px;
        }
    }

    .ios {
        --button-color: #4fb1dd !important;
    }

    .md {
        --button-color: #fff;
    }

    &::part(header) {
        font-weight: bold;
    }
}

.alert-wrapper,
.loading-wrapper {
    z-index: 99;
}

button.alert-button {
    color: $grey;

    &.logout-confirm {
        color: $danger;
        font-weight: bold;
    }
}

.mix-modal {
    transition: 0.3s;

    .modal-content {
        --padding-bottom: 42px;
        --padding-end: 36px;
        --padding-start: 36px;
        --padding-top: 42px;

        width: 100%;
        max-width: 800px;
        height: 100%;
        max-height: 612px;

        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        border-radius: 8px !important;
        --border-radius: 8px !important;

        border-radius: 6px;
        box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);

        transition: 0.3s all !important;

        scroll-behavior: smooth;
        padding: 4px;
    }

    .error {
        font-size: 10px;
    }

    .ui__col {
        margin-top: 0;
        padding: 0 10px;
    }

    .btn-close {
        position: fixed;
        right: 20px;
        top: 12px;

        cursor: pointer;

        &:hover {
            ion-icon {
                box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.35);
            }
        }

        ion-icon {
            width: 20px;
            height: 20px;
            background: #fff;
            border-radius: 100px;
            box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);

            transition: 0.3s;
        }
    }
}

.add-ticket .modal-content {
    height: 600px;

    --padding-bottom: 32px;
}

.add-ticket-success,
.add-schedule-item-success {
    .modal-content {
        max-width: 420px;
        max-height: 272px;

        --padding-bottom: 0;
    }

    .ui__label,
    ion-input {
        color: #52AFCB !important;
        font-size: 21px;
    }

    .ui__label {
        width: max-content;
        margin: 0;
    }

    .btn {
        margin-top: 30px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .text {
            color: $grey;
            margin-top: 0;
            margin-left: 8px;
        }
    }
}

.add-ticket-success {
    .modal-content {
        max-width: 420px;
        height: 272px;
    }
}

.add-schedule-item-success .modal-content {
    max-width: 800px;
    max-height: 320px;
}

.show-stream-key .modal-content {
    max-width: 700px;
    max-height: 220px;

    display: flex;
    align-items: center;
    justify-content: center;

    .wrapper {
        width: 100%;
        height: 100%;

        margin: auto;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .ui__row {
            background: rgba(0, 0, 0, 0.03);

            border: 1px solid $grey;
            padding: 4px 8px;
            border-radius: 6px;

            font-size: 20px;

            display: flex;
            align-items: center;

            &>*:first-child {
                font-weight: bold;
            }

            &>*+* {
                margin-left: 6px;
            }
        }
    }
}