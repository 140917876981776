.voiceChat_ {
    &body {}

    &button {
        .participant {
            width: 100%;

            border: 1px solid $grey;
            border-radius: 4px;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .name {
                padding: 24px 0;
                font-weight: bold;
                color: #5D5D5D;
            }

            ion-button {
                width: 100%;
                margin-bottom: -3px;
            }

            .voiceChatBtn {
                width: 100%;
                text-align: center;
                border-top: 1px lightgray solid;
                background-color: lightgray;

                .appointment__onOffBtn {
                    background-color: white;
                    opacity: 0.75;
                    margin: 0.5rem;
                }
    
                .appointment__logoutBtn {
                    margin: 0.5rem;
                    background-color: white;
                    color: red;
                    opacity: 0.75;
                }
            }
        }
    }

    &exhibitor_switch {
        text-align: center;
    }

    &liveIndicator {
        margin-top: 24px;
        text-align: center;
    }
}