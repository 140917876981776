.login-container {
    width: 100%;
    max-width: 1700px;
}

.login__ {
    &wrapper {
        form {
            width: 100%;
            height: 100%;

            .event-options {
                margin-top: 40px;

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .event {
                    position: relative;
                    cursor: pointer;

                    transition: 0.3s;

                    &:hover {
                        .countdown {
                            display: unset;
                            visibility: visible;
                            opacity: 1;
                        }
                    }

                    .countdown {
                        visibility: hidden;
                        opacity: 0;
                        display: none;

                        transition: 0.3s;

                        text-align: center;

                        background: rgba(0, 0, 0, 0.5);
                        color: #fff;
                        border-radius: 16px;

                        z-index: 10;

                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;

                        * {
                            margin-top: 0;
                        }

                        span {
                            font-size: 32px;

                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }

                    &.blue {
                        img {
                            border: 2px solid #334cce;
                        }
                    }

                    img {
                        margin-top: 0;

                        position: relative;
                        z-index: 5;
                        border: 2px solid $primary;
                        border-radius: 16px;
                        box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.2);

                        transition: 0.3s;
                    }

                    img:hover {

                        box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.2);
                        transform: scale(1.02, 1.02);
                    }
                }
            }
        }

        .select-event {
            margin-top: 48px;

            display: flex;
            flex-direction: column;

            .le-point-logo {
                width: 300px;
            }
        }

        .enter-nip {
            .event-logo {
                margin-top: 20px;

                border-radius: 15px;

                display: flex;
                justify-content: center;
                align-items: center;

                .image {
                    width: 100%;
                    // max-width: 600px;
                    max-height: 240px;

                    img {
                        width: 100%;
                        position: relative;

                        box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.2);

                        z-index: 1;

                        margin: 0 auto;
                        padding: 12px auto 60px auto;

                        object-fit: cover;
                        max-height: 100px;
                        height: 100%;
                    }
                }

                .content {
                    width: 100%;

                    padding: 8px 16px;

                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;

                    .phone,
                    .contacts {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                    }

                    .contacts {
                        margin-left: 32px;
                        align-items: flex-start;

                        .email,
                        .phone {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            a {
                                color: #000;
                            }

                            ion-icon {
                                margin-right: 16px;
                            }
                        }
                    }
                }
            }

            .back-btn {
                box-shadow: none;
                --box-shadow: none;

                margin: auto;
            }

            .partner-logos {
                display: none;

                @media (min-width: 1280px) {
                    display: flex;
                }

                width: 100%;
                margin: 10px 0 30px 0;

                height: 55px;
                max-height: 55px;

                flex-direction: row;
                justify-content: center;
                align-items: center;

                ion-img {
                    width: 80px;
                    margin: 0;

                    &+ion-img {
                        margin-left: 10px;
                    }
                }
            }
        }

        @media (max-width: 1280px) {
            &.find-events {
                .page-meta {
                    .ui__h2 {
                        margin-left: calc(10px + 9%);
                    }
                }
            }

            .page-content {
                .timestamps {
                    width: calc(100% - 80px) !important;
                    margin-left: calc(12px + 5.5%);
                }
            }
        }

    }

    &cover {
        z-index: 10;
    }

    &join-form {
        padding: 0 40px;

        .ui__h3 {
            text-align: center;
        }

        &>*+* {
            margin-top: 24px;
        }
    }

    // Phones
    @media (max-width: 479px) {
        &wrapper {
            .ui__row.page {
                width: 100%;
                flex-wrap: wrap;
                flex-direction: column;

                .login__cover {
                    ion-img {
                        width: 100%;
                        height: 35vh;
                        // margin-top: 20px;

                        object-fit: cover;
                        object-position: center;
                    }
                }

                .login__content {
                    .login__events-list {
                        padding: 0;

                        .ui__h3 {
                            padding: 0 12px;
                        }

                        .event-options {
                            margin-top: 16px;
                        }
                    }

                    .login__join-form {
                        .ui__h3 {
                            padding-top: 15px;

                            text-align: center;
                            font-size: 25px;
                            line-height: 1.1;
                        }

                        .back-btn {
                            margin-top: 0;
                            margin-left: 0;

                            box-shadow: none;
                            --box-shadow: none;
                        }
                    }

                    .select-event {
                        margin-top: 5px;

                        .event-options {
                            width: 100%;

                            .event {
                                img {
                                    border-radius: 0;
                                    border-width: 2px;
                                    border-right: none;
                                    border-left: none;

                                    box-shadow: 2px 6px 10px rgba(0, 0, 0, .2);
                                }
                            }
                        }

                        .welcome-message {
                            line-height: 1.3;
                        }

                        .ui__h3 {
                            font-size: 25px;
                            line-height: 1.1;
                        }

                        .powered {
                            margin: 0 auto;
                        }
                    }

                    .enter-nip {
                        .event-logo {
                            border: 2px solid #000;
                            border-left: none;
                            border-right: none;

                            box-shadow: 2px 6px 10px rgba(0, 0, 0, .2);

                            img {
                                max-width: 100%;
                            }
                        }
                    }
                }
            }

            .event-logo {
                height: 150px !important;
            }
        }
    }

    // Tablets
    @media (min-width: 480px) and (max-width: 1279px) {
        &join-form {
            min-width: 400px;
            max-width: 760px;
            margin: auto;
        }
    }

    // Desktop resolution
    @media (min-width: 1280px) {
        &cover {
            min-height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;

            ion-img {
                width: 37vw;
                height: 100vh;

                object-fit: cover;
            }
        }

        &join-form {
            min-width: 400px;
            max-width: 480px;

            margin: auto;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .ui__h3 {
                text-align: center;
            }

            .back-btn {
                margin: auto;
                --box-shadow: none;
                box-shadow: none;

                --color: $dark !important;
                color: $dark !important;

                margin-bottom: 25px;
            }
        }

        .login__content {
            .select-event {
                ion-img {
                    width: 800px;
                }
            }

        }

        &wrapper {
            .enter-nip {
                .event-logo {
                    margin: 50px 0 25px 0;

                    background: #F5F5F5;

                    display: flex;
                    flex-direction: column;

                    ion-img,
                    img {
                        border-radius: 15px;
                        box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.16);
                    }
                }
            }
        }
    }

    // Tablets horizontal
    @media (min-width: 769px) and (max-width: 1279px) {
        &cover {
            min-height: 100vh;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            ion-img {
                width: 100%;
                height: 100vh;

                margin-left: auto;

                object-fit: cover;
            }
        }

        &wrapper {
            .select-event {
                .event-options {
                    .event {
                        padding: 0 16px;
                    }
                }
            }
        }
    }

    // Tablets vertical
    @media (min-width: 480px) and (max-width: 768px) {
        &wrapper {
            .ui__row.page {
                width: 100%;
                flex-wrap: wrap;
                flex-direction: column;

                .login__cover {
                    ion-img {
                        width: 100%;
                        height: 32vh;
                        margin-top: 20px;
                        padding: 0 10%;

                        object-fit: cover;
                        object-position: top;
                    }
                }

                .login__content {
                    .select-event {
                        margin-top: 5px;
                    }
                }
            }

            .enter-nip {
                .fc__powered-by-mixabl {
                    position: absolute;
                    right: 20px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.find-events__page {
    .events-container {
        display: flex;
        flex-wrap: wrap;

        .page-content {
            width: 100%;
            padding-left: 40px;

            * {
                margin-top: 0;
            }

            .timestamps {
                width: 100%;
                flex: 1;

                margin-top: 15px;

                display: flex;
                flex-direction: column;

                .timestamp-group {
                    margin-bottom: 50px;

                    display: flex;
                    flex-direction: row;

                    .entry {
                        color: #666;
                        text-transform: uppercase;
                        min-width: 72px;
                        text-align: right;
                    }

                    .events {
                        width: 100%;
                        margin-left: 10px;

                        display: flex;
                        flex-direction: column;

                        .event {
                            position: relative;

                            width: 100%;
                            max-width: 1200px;
                            flex: 1;

                            display: flex;
                            flex-direction: row;

                            transition: 0.3s;

                            cursor: pointer;

                            &:hover {
                                transform: scale(1.005);

                                .placeholder img {
                                    border-radius: 0;
                                    transform: translateY(-8px) scale(1.1);
                                }
                            }

                            &+.event {
                                margin-top: 20px;
                            }

                            .placeholder {

                                width: 100%;
                                max-width: 400px;

                                margin-top: 20px;
                                margin-left: 20px;

                                img {
                                    position: relative;
                                    z-index: 1;

                                    object-fit: cover;

                                    width: 400px;
                                    height: 300px;

                                    border-radius: 0 0 10px 10px;

                                    transition: 0.5s;
                                }
                            }

                            &>.content {
                                width: 100%;
                                padding: 17px 0 10px 40px;

                                .header {
                                    display: flex;
                                    flex-direction: column;

                                    width: 100%;
                                    height: 84px;

                                    .name {
                                        font-size: 21px;
                                    }

                                    .name,
                                    .description {
                                        z-index: 1;
                                    }

                                    .bg {
                                        position: absolute;
                                        top: 0;
                                        right: 0;

                                        width: 100%;
                                        height: 84px;

                                        border-radius: 10px 10px 0 0;

                                        z-index: 0;

                                        background: $primary;
                                    }
                                }

                                .content {
                                    width: 100%;

                                    .meta {
                                        display: flex;
                                        flex-direction: row;

                                        .info,
                                        .contacts {
                                            width: 50%;
                                        }

                                        .info {}

                                        .contacts {

                                            .phone,
                                            .email {
                                                display: flex;
                                                align-items: center;

                                                text-decoration: underline;

                                                &,
                                                .text {
                                                    transition: 0.2s;
                                                }

                                                .text {
                                                    padding: 2px;

                                                    a {
                                                        color: unset;
                                                    }
                                                }

                                                &:hover {
                                                    .text {
                                                        background: rgba(0, 0, 0, .1);
                                                        border-radius: 2px;
                                                        transform: scale(1.05);
                                                    }
                                                }

                                                ion-icon {
                                                    margin-right: 10px;
                                                    width: 16px;
                                                    height: 16px;
                                                    min-width: 16px;
                                                    min-height: 16px;
                                                }
                                            }

                                        }
                                    }

                                    .logos {
                                        width: 100%;

                                        padding-top: 35px;
                                        padding-bottom: 20px;

                                        border-bottom: 2px solid $primary;

                                        display: flex;
                                        flex-direction: row;
                                        flex: 1;

                                        .presentor,
                                        .sponsor {
                                            width: 50%;

                                            font-size: 12px;

                                            display: flex;
                                            flex-direction: row;
                                            flex: 1;

                                            .images {
                                                margin-left: 20px;
                                                display: flex;
                                                align-self: flex-start;
                                                flex-wrap: wrap;

                                                img {
                                                    max-width: 135px;
                                                }

                                                img+img {
                                                    margin-left: 10px;
                                                }
                                            }

                                            .text {
                                                min-width: 84px;
                                            }

                                            .logos {
                                                width: 100%;

                                                display: flex;
                                                flex-direction: row;
                                                flex-wrap: wrap;
                                            }
                                        }

                                        .presentor {
                                            max-width: 300px;

                                            .images {

                                                img {
                                                    width: 100%;
                                                    max-width: 200px;
                                                }
                                            }

                                            margin-right: 35px;
                                        }

                                        .sponsor {
                                            margin-left: auto;

                                            .images {
                                                display: flex;
                                                align-items: center;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}