:root {
    background: #ffffff;

    &,
    ion-content {
        --background: #ffffff;
    }

    .no-ripple {
        --ripple-color: transparent;
    }

    ion-tab-bar {
        --background: #F5F5F5;

        display: flex;
        padding: 0;

        ion-tab-button {

            ion-icon,
            ion-label {
                transition: 0.2s;
            }

            ion-icon {
                width: 26px;
                height: 26px;
            }
        }

        .custom-btn {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
        }

        display: none;

        // Hiding on phones and vertical tablets
        @media (max-width: 768px) {
            display: flex;

            ion-label {
                display: none;
            }
        }
    }

    iframe {
        border: 0;
    }

    .hide-on-mobile {
        display: none;

        @media (min-width: 480px) {
            display: block;
        }
    }

    .show-on-mobile {
        @media (max-width: 480px) {
            display: unset;
        }
    }

    .mobile-only {
        display: none;

        @media (max-width: 480px) {
            display: unset;
        }
    }

    .lg-only {
        display: unset;

        @media (max-width: 1599px) {
            display: none;
        }
    }

    .hide-on-desktop {
        display: none;

        @media (max-width: 1279px) {
            display: block;
        }
    }

    .btn {
        cursor: pointer;
        transition: 0.3s;

        &:hover {
            opacity: 0.8;
        }

        &:active {
            opacity: 0.3;
        }

        &.disabled:hover {
            opacity: 1;
        }

        &.disabled:active {
            opacity: 1;
        }
    }

    .air {
        align-items: flex-end;
    }
}

.slick-slider.slick-initialized {
    min-height: 600px;
    height: 100%;
    max-height: 600px;
    margin-top: 16px;

    button.slick-arrow,
    button.slick-arrow:hover {
        background: rgba(0, 0, 0, 0.5);
        border-radius: 100px;
        z-index: 99999;
        padding-top: 1px;
    }

    .slick-slide {
        width: 100%;
        min-height: 600px;
        height: 100%;
        max-height: 600px;
        background: #232323;
    }

    .slide {
        width: 100%;
        min-height: 600px;
        height: 100%;
        max-height: 600px;
        background: #232323;

        text-align: center;
        margin: auto;

        display: flex !important;
        justify-content: center;
        align-items: center;

        &.offering {
            max-width: 420px;
            max-height: 420px;
            display: flex;
            flex-direction: column;

            .info {

                .name {
                    font-size: 16px;
                    font-weight: bold;
                }

                .name,
                .description {
                    color: #fff;
                }
            }
        }

        video {
            width: 100%;
            height: 100%;
            min-height: 600px;
            max-height: 600px;
        }

        img {
            // width: 100%;
            // height: 100%;
            max-height: 600px;
            object-fit: contain;
        }
    }
}

.fw {
    width: 100%;
}