.general_chat {
    &_frame {
        --frame-width: 450px;
        --frame-height: 481px;
        --folder-height: 40px;

        z-index: 101;

        width: var(--frame-width);
        height: var(--frame-height);

        // position: fixed;
        position: sticky;
        top: calc(100% - (var(--frame-height) + 6px));
        bottom: 0;
        right: 0;
        margin-left: calc(-20%);

        @media (max-width: 1280px) {
            position: fixed;
            top: unset;
            margin-left: unset;
        }

        border-top: 12px solid #000;
        border-top-left-radius: 4px;

        transition: 0.5s;
        transform: translateX(0);

        &.closed {
            transform: translateX(calc(100% - var(--folder-height)));
        }
    }

    &_folders {
        // background: blue;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        width: 100%;
        height: var(--folder-height);

        transform: rotate(-90deg) translate(0, -100%);
        transform-origin: 100% 0;
        position: absolute;
        left: calc(-100% + var(--folder-height));
    }

    &_folder {
        background: #CCCBCB;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;

        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        text-transform: uppercase;

        transition: 0.3s;
        cursor: pointer;

        .mixabl,
        .name,
        .chat {
            color: #fff;
            opacity: 0.5;
            transition: 0.3s;
        }

        .mixabl {
            font-size: 9px;
            margin-bottom: -9px;
        }

        .name {
            max-width: 12ch;
            font-size: 18px;
            font-weight: 700;
        }

        .chat {
            font-size: 18px;
        }

        &:hover {

            .mixabl,
            .name,
            .chat {
                opacity: 1;
            }
        }

        &:last-child {
            border-top-right-radius: 0;
        }

        &.open {
            background: $secondary;

            .mixabl,
            .name,
            .chat {
                opacity: 1;
            }
        }

        &+* {
            margin-left: 5px;
        }
    }

    &_body {
        height: 100%;

        background: #f9f9f9;

        margin-left: 40px;
    }

    &_chat {
        height: 100%;
        border: 1px solid #000;
        border-top: 0;
        border-bottom-left-radius: 3px;

        .chat-box {
            height: 100%;

            .messages {
                height: calc(100% - 60px);
                overflow-y: auto;
                padding: 8px;

                &::-webkit-scrollbar {
                    display: none;
                }

                scrollbar-width: none; // Firefox
                -ms-overflow-style: none; // IE and Edge

                .chat-disabled {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .message {
                    width: 100%;
                    padding: 4px;
                    display: flex;

                    &:hover {
                        background: rgba(0, 0, 0, 0.07);
                    }

                    .make-emojis-large {
                        font-size: 20px;
                    }

                    .name {
                        width: 100%;
                        max-width: 54px;

                        margin-top: 4px;
                        margin-right: 16px;

                        font-size: 12px;
                        text-align: center;

                        display: flex;
                        justify-content: center;
                    }

                    .content {
                        width: 100%;

                        .time {
                            margin-right: 10px;

                            color: $grey;
                            font-size: 10px;
                            text-align: right;
                        }

                        .text {
                            position: relative;

                            width: 100%;
                            max-width: 274px;
                            padding: 10px;

                            background: #fff;
                            border: 1px solid #CCCBCB;
                            border-radius: 4px;

                            &:before {
                                content: "\A";
                                border-style: solid;
                                border-width: 6px 8px 6px 0;
                                border-color: transparent #CCCBCB transparent transparent;
                                position: absolute;
                                left: -9px;
                            }
                        }
                    }

                    .delete {
                        margin-left: 4px;
                        margin-top: 4px;

                        ion-icon {
                            cursor: pointer;

                            &:hover {
                                background: $danger;
                                border-radius: 100px;
                            }
                        }
                    }
                }
            }

            .sender {
                height: 60px;

                .ui__input {
                    height: 60px;
                    position: relative;

                    border: 0;
                    border-radius: 0;
                    border-bottom-left-radius: 3px;
                    border-top: 1px solid #000;

                    --padding-end: calc(120px);

                    transition: 0.3s;

                    ion-button.ui__button {
                        position: absolute;
                        right: 8px;

                        min-width: unset;
                        width: 100%;
                        max-width: 100px;

                        transition: 0.3s;
                    }
                }
            }
        }
    }
}