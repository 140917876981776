.admin__ {
    &navigation {
        width: 100%;
        margin-bottom: 24px;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        border-bottom: 1px solid rgba(0, 0, 0, 0.07);

        .item {
            width: max-content;
            color: #444356;
            font-weight: bold;

            padding: 12px;

            cursor: pointer;
            transition: 0.3s;

            &+.item {
                margin-left: 24px;
            }

            &:hover,
            &.selected {
                border-radius: 8px 8px 0 0;
                background: rgba(0, 0, 0, 0.07);
            }
        }
    }

    &table-pagination {
        margin: 30px 0;
        display: flex;
        flex-direction: row;

        .prev,
        .next,
        .pages {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .prev,
        .next {
            &.disabled {
                cursor: default;
                color: gray;
            }

            .text {
                font-size: 14px;
            }
        }

        .prev {
            .text {
                margin-left: 12px;
            }
        }

        .next {
            .text {
                margin-right: 12px;
            }
        }

        .pages {
            margin: 0 20px;
            font-size: 16px;

            .page {
                min-width: 32px;
                min-height: 32px;

                border: 2px solid #CCCBCB;
                border-radius: 6px;

                display: flex;
                align-items: center;
                justify-content: center;

                &+.page {
                    margin-left: 6px;
                }

                &.currentPage {
                    color: $secondary;
                    border-color: $secondary;
                }
            }
        }
    }

    &ticket-table,
    &schedule-table,
    &exhibitors-table,
    &events-table {
        width: 100%;

        .header {
            display: flex;
            flex-direction: row;
            align-items: center;

            flex-wrap: wrap;

            .ais-SearchBox {
                width: 50%;
                max-width: 350px;

                margin-left: auto;

                display: flex;
                align-items: center;
                justify-content: flex-end;

                form {
                    input {
                        @extend .ui__input;
                        height: 32px;
                        padding-left: 10px;
                    }

                    button {
                        display: none;
                    }
                }
            }
        }

        .content {
            margin-top: 36px;
            margin-bottom: 46px;
            overflow-y: hidden;

            display: flex;
            flex-direction: column;

            white-space: nowrap;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;

            .thumbnail-img {
                min-width: 80px;
                width: 100%;
                max-width: 100px;
                min-height: 80px;
                height: 100%;
                max-height: 100px;

                padding: 5px;

                object-fit: contain;

                border: 1px solid #ddd;
                border-radius: 4px;
                box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.07);
            }

            table {
                margin-bottom: 0;
            }

            .none {
                font-style: italic;
                color: #CCCBCB;
            }

            .btn-edit,
            .btn-pass-reset,
            .btn-delete {
                margin: auto;
                box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
                cursor: pointer;

                transition: 0.3s;

                &:hover {
                    opacity: 0.6;
                }

                &:active {
                    opacity: 1;
                    background: #000;
                }
            }

            .btn-edit,
            .btn-pass-reset {
                width: 32px;
                height: 32px;
                border-radius: 7px;
            }

            .btn-delete {
                width: 20px;
                height: 20px;
                border-radius: 100px;
            }
        }
    }

    &exhibitors-table {
        .content {
            .exhibitors-table {
                width: 100%;
                max-width: 1895px;

                .exhibitor-type {
                    text-transform: capitalize;
                    font-weight: bold;
                }
            }
        }
    }

    &events-table {
        .content {
            .events-table {
                width: 100%;
                max-width: 1800px;
            }
        }
    }

    &add-ticket,
    &add-schedule-item,
    &add-exhibitor,
    &add-event {
        margin-left: 16px;

        overflow: hidden;
        transition: max-height 0.5s ease-in-out;

        .btn {
            width: 100%;
            padding: 12px;

            height: max-content;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            cursor: pointer;

            transition: 0.3s;

            &:hover {
                background: rgba(0, 0, 0, 0.07);
            }

            .icon {
                transition: 0.3s;
                display: flex;
                align-self: center;
                justify-content: center;
            }

            .text {
                margin-left: 10px;

                color: #A3A3A3;
                font-size: 14px;
            }
        }
    }
}