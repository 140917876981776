.home__ {
    &wrapper {
        .my-meeting-table {
            width: 100%;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            a {
                width: 100%;
                text-decoration: none;
            }

            .meeting-card {
                width: 100%;
                max-width: 720px;
                min-height: 36px;

                border: 1px solid $grey;
                border-radius: 6px;
                background: #fcfcfc;
                box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.16);

                padding: 8px 16px;

                display: flex;
                flex-direction: row;
                flex-grow: 1;

                cursor: pointer;
                transition: 0.25s;

                &+* {
                    margin-top: 16px;
                }

                &:hover {
                    opacity: 0.8;
                    transform: scale(1.01);
                }

                * {
                    margin-top: 0;
                }

                .time {
                    margin-right: 24px;

                    color: $dark;
                    font-size: 16px;
                    font-weight: bold;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .name {
                    padding: 12px 24px;

                    color: #000;
                    font-size: 18px;

                    display: flex;
                    flex: 1 0 auto;
                }

                .btn-close {
                    display: flex;
                    margin: auto;
                    margin-left: 16px;

                    transition: 0.3s;

                    &:hover {
                        background: #EB6363;

                        ion-icon {
                            color: #fff;
                        }
                    }
                }
            }
        }

        @media (max-width: 1280px) {
            .fc__exhibitors-list {
                padding: 0 8px;
                max-height: unset;
            }

            .home-description {
                padding-left: 10px;
            }

            .page-actions {
                margin-top: 32px;
            }
        }

        embed {
            min-height: 742px;
        }
    }

    &grid {
        width: 100%;
        grid-template-columns: auto 370px !important;

        @media (max-width: 1280px) {
            grid-template-columns: span 370px !important;
        }

        @media (max-width: 1680px) {
            grid-template-columns: auto !important;
        }
    }
}