.fade-enter {
    opacity: 0;
}

.fade-exit {
    opacity: 1;
}

.fade-enter-active {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
    transition: opacity 600ms;
}

.fade-bottom-enter {
    opacity: 0;
    transform: translateY(-40px);
}

.fade-bottom-exit {
    opacity: 1;
    transform: translateY(0px);
}

.fade-bottom-enter-active {
    opacity: 1;
    transform: translateY(0px);
}

.fade-bottom-exit-active {
    opacity: 0;
    transform: translateY(-40px);
}

.fade-bottom-enter-active,
.fade-bottom-exit-active {
    transition: all 300ms;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
}
  
@keyframes marquee {
    0% { left: 0; }
    100% { left: -100%; }
}
