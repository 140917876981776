.double-auth-modal_ {
  .title-text {
    text-align: center;
    background: #232323;
    color: whitesmoke;
    padding: 20px;
    border-radius: 5px;
  }

  .description {
    margin-top: 5%;
    text-align: center;
  }

  .button-option-container {
    margin-top: 50%;
    text-align: center;

    .button {
      margin-right: 20px;
      margin-left: 20px;
    }
  }
}