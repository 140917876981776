.exhibitors-page {

    .slider {
        max-height: 240px;

        img,
        ion-img {
            height: 240px;
            max-height: 240px;
            object-fit: contain;
        }
    }

    .ui__container.profile {

        .gold-exhibitor-card,
        .silver-exhibitor-card,
        .exhibitor-card {
            max-width: 100%;
            width: 100%;

            .meta {
                display: flex;
                align-items: center;
            }

            .description {
                display: none;
            }
        }

        .gold-exhibitor-card {
            @media (max-width: 480px) {
                .content {
                    height: auto;
                    flex-direction: column;
                }
            }

            .content {
                img {

                    width: 100%;
                    max-width: 200px;
                    height: 100%;
                    max-height: 80px;
                    object-fit: contain;
                }
            }
        }

        .silver-exhibitor-card {
            padding: 0;

            .content {

                @media (min-width: 1024px) {
                    height: 120px;
                    flex-direction: row;
                }

                img {
                    width: 100%;
                    max-width: 200px;
                    height: 100%;
                    max-height: 80px;
                    object-fit: contain;
                }

                .cta {
                    margin-top: 0;
                }

                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .exhibitor-card {
            margin: 0;
            padding: 20px;

            @media (min-width: 1024px) {
                height: 120px;
                flex-direction: row;
            }

            img {
                width: 100%;
                max-width: 200px;
                height: 100%;
                max-height: 80px;
                object-fit: contain;
            }

            .cta {
                margin-top: 0;
            }

            display: flex;
            align-items: center;
            justify-content: center;
        }

        .ui__h4 {
            text-transform: uppercase;
        }

        .form {
            width: 30%;
            margin-top: 80px;

            @media (min-width: 1024px) {
                padding-left: 16px;
            }

            .ui__textarea {
                margin-bottom: 10px;
                --padding-end: 8px;

                &[aria-disabled="true"] {
                    background: #fff;
                    opacity: 0.5;
                }
            }

            .fc__powered-by-mixabl {
                display: flex;
                align-self: center;
            }

            .gallery {
                .slider {
                    margin: 0;

                    ion-slide {
                        margin: auto;
                    }
                }
            }
        }

        @media (max-width: 480px) {

            .video,
            .video iframe {
                min-height: 240px !important;
            }
        }

        @media (max-width: 1024px) {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;

            .about.ui__col {
                width: 100%;
                padding-right: 0;
            }

            .form.ui__col {
                width: 100%;
                padding-right: 0;
            }

            ion-text,
            .ui__h4 {
                padding: 0 10px;
            }

            .logo {
                margin: auto;
            }
        }

        @media (max-width: 1366px) {

            ion-text,
            .ui__h4 {
                padding: 0 10px;
            }
        }
    }

    .exhibitors__ {
        &wrapper {
            .heading {
                margin-top: 50px;

                .description {
                    margin-top: 0;
                }
            }

            .sort-section {
                width: 100%;

                padding: 16px 20px;
                border: 1px solid #000;
                border-left: 0;
                border-right: 0;

                display: flex;
                flex-direction: row;
                align-self: center;

                @media (max-width: 480px) {
                    display: none;
                }

                * {
                    margin-top: 0;
                }

                .results-str {
                    color: $grey;
                    font-style: italic;
                }

                .action-btns {
                    margin-left: auto;

                    display: flex;
                    align-items: center;

                    @media (max-width: 1024px) {
                        display: none;
                    }

                    * {
                        cursor: pointer;
                        color: #9CA6A8;
                    }

                    .grid-btn {
                        width: 21px;
                        height: 21px;

                        margin-right: 8px;

                        opacity: 0.5;
                        transition: 0.3s;

                        &.activated,
                        &:hover {
                            opacity: 1;
                        }
                    }

                    .list-btn {
                        width: 24px;
                        height: 18px;

                        opacity: 0.5;
                        transition: 0.3s;

                        &.activated,
                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }

            .gold {
                margin: 0 10px;

                display: flex;
                flex-direction: column;
            }

            .silver,
            .bronze {
                display: flex;
                flex-direction: row;

                flex-wrap: wrap;
            }

            .scrollable {
                width: 100%;
                max-height: 1000px;
                padding: 10px;
                padding-right: 12px;

                overflow-y: auto;
                overflow-x: hidden;

                @media (max-width: 1024px) {
                    max-height: unset;
                }

                @supports (-webkit-appearance:none) and (not (overflow:-webkit-marquee)) and (not (-ms-ime-align:auto)) and (not (-moz-appearance:none)) {
                    @media (min-width: 1280px) and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) {
                        overflow-y: hidden;

                        &:hover {
                            padding-right: 4px;
                            overflow-y: auto;
                        }

                        &::-webkit-scrollbar {
                            transition: opacity 0.25s linear;
                            width: 8px;
                        }

                        /* Track */
                        &::-webkit-scrollbar-track {
                            box-shadow: inset 0 0 5px $scrollbar-bg;
                            border-radius: 10px;
                        }

                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: $scrollbar-thumb;
                            border: 1px solid #FFFFFF;
                            border-radius: 10px;
                        }

                        /* Handle on hover */
                        &::-webkit-scrollbar-thumb:hover {
                            background: #ccc;
                        }
                    }
                }

                .other {
                    width: 100%;

                    border-top: 3px solid #000;
                    padding-top: 20px;

                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                }
            }
        }

        &schedule-meeting {
            .meeting-time-table {
                width: auto;
                margin: 15px 10px;
                padding-top: 12px;
                padding-bottom: 12px;

                border-top: 3px solid #000;
                border-bottom: 3px solid #000;

                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                .timestamp {
                    width: 100%;
                    max-width: 25%;
                    margin-top: 0;
                    padding: 10px 18px;

                    cursor: pointer;

                    color: $dark;

                    font-size: 20px;
                    font-weight: bold;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    transition: 0.2s;

                    &.selected {
                        background: rgba(0, 0, 0, 0.05);
                        color: #fff;
                        border-radius: 8px;
                    }
                }
            }

            .meeting-form {
                padding: 0 20px;

                .schedule__error {
                    color: red !important;
                }
            }
        }

        &profile-grid {
            width: 100%;
            grid-template-columns: 325px minmax(325px, 100%) !important;

            @media (max-width: 1680px) {
                grid-template-columns: 220px minmax(220px, 100%) !important;
                padding-right: 32px;
            }

            // @media (max-width: 1280px) { }
        }
    }

    .page-meta {

        .exhibitor-card,
        .bronze-exhibitor-card,
        .silver-exhibitor-card,
        .gold-exhibitor-card {
            width: 100%;
            max-width: 100%;

            padding: 0;
            margin-bottom: 64px;
        }

        .voice-calling {}
    }
}

.popup {
    .btn-action {
        height: 37px;
        min-height: 37px;

        margin: 0 10px 10px 0;

        --border-radius: 0;
        border-radius: 0;
        box-shadow: 3px 3px 12px rgba(102, 102, 102, 0.5);

        font-weight: bold;
        text-transform: none;

        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        ion-icon {
            margin-right: 9px;
        }
    }

    .name,
    .booth {
        font-size: 17px;
        font-weight: bold;
    }

    .video {
        iframe {
            border: 0;
            width: 100%;
            height: 240px;
        }
    }
}

.booth__ {
    &wrapper {
        .video {
            max-height: 240px;
            overflow: hidden;

            // Temporary
            .stream {
                height: 240px;
                background: #000;
            }
        }
    }
}