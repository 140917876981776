@font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins-Medium.eot');
    src: url('../../assets/fonts/Poppins-Medium.eot') format('embedded-opentype'),
        url('../../assets/fonts/Poppins-Medium.ttf') format('truetype'),
        url('../../assets/fonts/Poppins-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins-Bold.eot') format('embedded-opentype'),
        url('../../assets/fonts/Poppins-Bold.ttf') format('truetype'),
        url('../../assets/fonts/Poppins-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

:root {
    --ion-font-family: 'Poppins', var(--ion-default-font);
    font-size: 14px;
    font-weight: 500;
    color: $satan;
}

.fwb {
    font-weight: bold;
}

.fwn {
    font-weight: normal;
}

.tac {
    text-align: center;
}

.ttu {
    text-transform: uppercase;
}

.dark {
    color: $dark !important;
}

.blue {
    color: #52AFCB !important;
}

.danger {
    color: $danger !important;
}

.success {
    color: $success !important;
}

.text-primary {
    color: $primary;
}