.ui__ {
    &content {
        width: 100%;

        display: flex;
        flex-direction: column;
    }

    &container {
        position: relative;

        display: flex;
        width: 100%;
        max-width: 1680px;

        @media (min-width:480px) and (max-width: 1280px) {
            display: flex;
            grid-column: 1/span 2;
            width: 100%;
        }
    }

    &row {
        width: 100%;

        display: flex;
        flex-direction: row;

        &.margin>* {
            margin: 4px;
        }
    }

    &col {
        width: 100%;

        display: flex;
        flex-direction: column;
    }

    &group {
        margin: 15px 0;

        display: flex;
        flex-direction: column;

        &>*+*, &>*+.MuiFormControl-root {
            margin-top: 15px;
        }
        
        ion-item * {
            margin-top: 0;
        }
    }

    &item {
        background: #fff;
        --color: #000;

        --padding-start: 10px;
        --color-activated: #52AFCB;
        --color-focused: #52AFCB;
        --color-hover: #52AFCB;
        --highlight-color-focused: #52AFCB;
        // border: 0.5px solid $grey;
        border-radius: 3px;

        display: flex;
        align-items: center;

        font-size: 14px;

        ion-input {
            margin-bottom: 2px;
            height: 48px;
        }

        &[aria-disabled="true"] {
            background: rgba(0, 0, 0, 0.05);
        }
    }

    &label {
        --color: $grey !important;
        color: $grey !important;

        display: flex;
        flex-direction: row;
    }

    &date-picker {
        width: 100%;
    }

    &input {
        background: #fff;
        color: #000;
        --color: #000;

        --padding-start: 10px;
        border: 0.5px solid $grey;
        border-radius: 3px;

        font-size: 14px;
        --placeholder-color: #A3A3A3;
        --placeholder-opacity: 1;

        &-hint {
            width: 50%;
            color: #000;

            text-align: center;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        &[aria-disabled="true"] {
            background: rgba(0, 0, 0, 0.05);
        }
    }

    &textarea {
        background: #fff;
        --color: #000;

        min-height: 80px;
        height: 80px;
        max-height: 80px;
        min-width: 100%;
        max-width: 100%;

        --padding-start: 10px;
        border: 0.5px solid $grey;
        border-radius: 3px;

        font-size: 14px;
        --placeholder-color: #A3A3A3;
        --placeholder-opacity: 1;

        &+.ui__button {
            margin-top: 10px;
        }

        &[aria-disabled="true"] {
            background: rgba(0, 0, 0, 0.05);
        }
    }

    &button {
        width: auto;
        min-width: 180px;
        height: 40px;

        margin-left: 0;
        margin-right: 0;

        --border-radius: 3px;
        border-radius: 3px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

        font-size: 17px;
        font-weight: bold;

        display: flex;
        align-items: center;
        justify-content: center;

        &[aria-disabled="true"] {
            background: rgba(0, 0, 0, 0.05);
        }

        &+& {
            margin-top: 9px;
        }

        &::part(native) {
            padding: 0;
        }

        ion-icon {
            max-width: 26px;
            margin-right: 8px;
        }

        &.back-btn {
            font-size: 15px;
            min-width: unset !important;
            max-width: 300px;
            margin-right: auto;

            color: $grey !important;
            --color: $grey !important;
            box-shadow: none;
            --box-shadow: none;

            @media (max-width: 1280px) {
                margin-left: 16px;
            }
        }

        &.blue-btn {
            color: #54ACD4 !important;
            --color: #54ACD4 !important;
        }
    }

    &h1 {
        color: $satan;
        font-size: 32px;
        font-weight: 500;
    }

    &h2 {
        color: #000;
        font-size: 21px;
    }

    &h3 {
        color: $satan;
        font-size: 18px;
        font-weight: 400;
    }

    &hr {
        height: 3px;
        width: 100%;
        background: #000;
    }
}