.m-auto {
    margin: auto;
}

.ml-auto {
    margin-left: auto;
}

.mr-auto {
    margin-right: auto;
}

.m-vauto {
    margin-top: auto;
    margin-bottom: auto;
}

.m-hauto {
    margin-left: auto;
    margin-right: auto;
}