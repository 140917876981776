.table__ {
    &root {
        width: 100%;
        margin-bottom: 45px;
        border-collapse: collapse;
    }

    &head,
    &body {

        .table__header-cell,
        .table__cell {
            padding: 6px 0;

            &:first-child {
                padding-left: 20px;
            }

            &:last-child {
                padding-right: 6px;
            }

            &+* {
                padding-left: 24px;
            }
        }
    }

    &body {
        border-top: 1px solid #52AFCB;
        border-bottom: 1px solid #52AFCB;

        .table__row {
            &:nth-child(even) {
                background: #F5F5F5;
            }

            &:nth-child(odd) {
                background: transparent;
            }
        }
    }

    &header-cell {
        font-size: 12px;
        font-weight: normal;
        text-transform: uppercase;
        text-align: left;

        cursor: pointer;
    }
}